/* You can add global styles to this file, and also import other style files */

// main framework
@import 'scss/settings/preset-variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
$pc-body-bg: map-get($preset-colors, 'preset-1', 'body');
$pc-blue: map-get($preset-colors, 'preset-1', 'primary');
$pc-secondary: map-get($preset-colors, 'preset-1', 'secondary');
@import 'scss/settings/custom-variables';
@import 'scss/settings/theme-variables';

@import 'node_modules/bootstrap/scss/bootstrap';
// main framework
@import 'scss/themes/generic';
@import 'scss/themes/general';

// Layouts
@import 'scss/themes/layouts/layouts';

// Componants
@import 'scss/themes/components/components';

// pages
@import 'scss/themes/pages/page';

@import 'scss/themes/customizer';
@import 'scss/style-preset.scss';

// fonts
@import url('scss/fonts/berry/berry-icons.css');
/** custom font icons - berry **/
@import 'scss/fonts/tabler-icons.min.css';


/* For WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 7px;
    /* width of the scrollbar */
    height: 7px;
    /* height of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #e1d8f1;
    /* color of the track */
}

::-webkit-scrollbar-thumb {
    background: #673ab77a;
    /* color of the scrollbar thumb */
    border-radius: 6px;
    /* roundness of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #673ab7;
    /* color of the scrollbar thumb on hover */
}


// Custom style
.text-danger {
    color: red;
}

.pointer {
    cursor: pointer;
}

.table-div {
    overflow-x: auto !important;
}

.table {
    tbody {

        tr,
        td {
            background-color: white !important;
        }
    }

    tbody tr:nth-child(even) {
        background-color: #eee;
    }

    tbody tr:nth-child(odd) {
        background-color: #fff;
    }

    .cus-table thead {
        top: 0;
        z-index: 99999;

        th {
            padding: 0.7rem 0.5rem;
        }
    }


    table {
        width: 100%;
        border-collapse: collapse;
    }

    th {
        color: black;
        font-weight: 600;
    }

    td,
    th {
        padding: 10px;
        text-align: left;
        font-size: 14px;
        min-width: 100px;
    }

    @media screen and (max-width: 768px) {
        td,
        th {
            padding: 10px;
            text-align: left;
            font-size: 14px;
            min-width: 200px;
        }
    }
}

.alignleft {
    min-width: 50px !important;
}

.btn-outline-secondary {
    color: #673ab7;
}

.btn-outline-secondary:hover {
    text-decoration: none;
    background-color: #fff;
    color: #673ab7;
    border-color: #673ab7;
}

.btn-outline-secondary:first-child:active {
    text-decoration: none;
    background-color: #fff;
    color: #673ab7;
    border-color: #673ab7;
}

.btn-outline-secondary.show {
    background-color: #fff;
    color: #673ab7;
    border-color: #673ab7;
}

.dropdown-item:active {
    background-color: #673ab7;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: #e1d8f1;
}
input[readonly] {
    background-color: #e1d8f169;
    &:focus{
        background-color: #e1d8f169;
    }
}
.form-control:disabled {
    background-color: #e1d8f169;
    opacity: 1;
}
.modal-content{
    background-color: white !important;
}

.input-error {
    border-color: red;
    &:hover{
        border-color: red !important;
        box-shadow: 0 0 0 1px red !important;
    }
    &:focus{
        border-color: red !important;
        box-shadow: 0 0 0 1px red !important;
    }
}
.text-warning{
    color: #ffc107;
}
.text-success{
    color: green;
}

.no-label > .form-control:not(:placeholder-shown){
    padding-top: 0.625rem !important;
}

.no-label > .form-control {
    padding: 0.75rem 0.75rem !important;
}