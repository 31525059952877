// ============================
//     Avtar css start
// ============================

.avtar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  font: {
    size: 18px;
    weight: 600;
  }
  min-width: 35px;
  min-height: 35px;

  &.avtar-xxs {
    width: 16px;
    height: 16px;
    font-size: 14px;
    border-radius: 5px;
  }
}
